import styles from "./sidebar.module.scss";
import { NavLink, Link } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List } from "@material-ui/core";
import classNames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useContext } from "react";
import { FleetContext, UserContext } from "contexts";
import { FeatureFlagContext } from "contexts";
import { Portal, PLBTypes, UserAccess } from "enums";
import UserRoleAccess from "utils/user-role-access.utils";

const ChildItem = (props) => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { item, notification, role } = props;
  const { fleet } = useContext(FleetContext);
  const parent = (
    <NavLink
      to={item.link}
      className={classNames(styles.listItem, {
        [`${styles.enabledLink}`]: !item?.items?.length,
        [`${styles.disabledLink}`]: item?.items?.length,
      })}
      activeClassName={classNames(item, styles.selectedMenu)}
    >
      <span className={styles.item}>
        <span className={styles.listIconRoot}>
          {item.iconComponent ? (
            <item.iconComponent />
          ) : (
            item.icon && <span className={`icon ${item.icon}`} />
          )}
        </span>
        <span className={styles.listText}>{item.label}</span>
      </span>
    </NavLink>
  );

  if (item.key === UserAccess.FleetCard && fleet.plbType === PLBTypes.PLB_HEDGING) {
    return <></>;
  }

  if (
    item.key === UserAccess.Fuel &&
    (fleet.plbType === PLBTypes.PLB_LOCQPAY || fleet.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO)
  ) {
    return <></>;
  }

  let filteredItems = item?.items?.filter(function (item) {
    return !(item.key === UserAccess.NonFuelTransaction && fleet.plbType === PLBTypes.PLB_HEDGING);
  });

  return (
    <Accordion elevation={0}>
      {item?.items?.length ? (
        <AccordionSummary expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}>
          {parent}
        </AccordionSummary>
      ) : (
        <div className={styles.name}>
          {parent}
          {notification[item.key]?.notification ? (
            <div className={styles.notification}></div>
          ) : null}
        </div>
      )}
      <AccordionDetails>
        <div className={styles.child}>
          {filteredItems
            ?.filter((child) => {
              return child.key ? hasFeature(`PAGE_${child.key}`) : true;
            })
            .map((child, childKey) => {
              return child.permission?.map((permission) => {
                return permission.role === role ? (
                  <NavLink
                    key={childKey}
                    to={child.link}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.listIconRoot}>
                      {child.icon && <span className={`icon ${child.icon}`} />}
                    </span>
                    <span className={styles.listText}>{child.label}</span>
                  </NavLink>
                ) : (
                  <></>
                );
              });
            })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const Sidebar = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { notification, fleet } = useContext(FleetContext);
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses.find((element) => {
    return element.portal === Portal.FLEET && element?.fleetId === fleet?.fleetId;
  });

  return (
    <div className={styles.sidebarContainer}>
      <List className={styles.sidebarList}>
        {UserRoleAccess.filter((item) => {
          return item.key ? hasFeature(`PAGE_${item.key}`) : true;
        }).map((item, index) => {
          if (!item) return <></>;
          if (!user.userAccesses)
            return (
              <span key={index}>
                {item.exact ? (
                  <NavLink
                    to={item.link}
                    exact={true}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.item}>
                      <span className={styles.listIconRoot}>
                        {item.icon && <span className={`icon ${item.icon}`} />}
                      </span>
                      <span className={styles.listText}>{item.label}</span>
                    </span>
                  </NavLink>
                ) : (
                  <ChildItem item={item} notification={notification} />
                )}
              </span>
            );

          return item.permission?.map((permission) => {
            return permission === role ? (
              <span key={index}>
                {item.exact ? (
                  <NavLink
                    to={item.link}
                    exact={true}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.item}>
                      <span className={styles.listIconRoot}>
                        {item.icon && <span className={`icon ${item.icon}`} />}
                      </span>
                      <span className={styles.listText}>{item.label}</span>
                    </span>
                  </NavLink>
                ) : (
                  <ChildItem item={item} role={role} notification={notification} />
                )}
              </span>
            ) : (
              <></>
            );
          });
        })}
      </List>
      <div className={styles.needHelpSection}>
        <div className={styles.needHelpLine}></div>
        <span>
          <Link
            to={{ pathname: "https://sites.google.com/seaoil.com.ph/plb-new-user-guide/?pli=1" }}
            className={classNames(styles.listItem)}
            target="_blank"
          >
            <span className={styles.item}>
              <span className={styles.listIconRoot}>
                <span className={`icon icon-help`} />
              </span>
              <span className={styles.listText}>Need a Guide?</span>
            </span>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
