import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const login = async ({ email, password, portalType }) => {
  const res = await Post(`${ApiPath.Login}`, { email, password, portalType });
  return res.data;
};

export const logout = async () => {
  const res = await Get(`${ApiPath.Logout}`);
  return res.data;
};

export const refresh = async ({ refreshToken }) => {
  const res = await Post(`${ApiPath.Refresh}`, { refreshToken });
  return res.data;
};

export const forgotPassword = async ({ email }) => {
  const res = await Post(`${ApiPath.SendResetPassword}/fleet`, { email });
  return res;
};

export const resetPassword = async ({ password, token }) => {
  const res = await Post(`${ApiPath.ChangePassword}`, { password, token });
  return res.data;
};

export const validateResetPasswordToken = async ({ token }) => {
  const res = await Get(`${ApiPath.ValidateToken}`, { token });
  return res;
};
