import { Intro, DataTable, Product, Text, Pill, PopOver } from "components/commons";
import { FleetContext, AnalyticsContext } from "contexts";
import { DateTime, Products, Page, PLBTypes } from "enums";
import { useApi, useFilter, useMount, useModal } from "hooks";
import React, { useCallback, useMemo, useContext } from "react";
import { fleetCardRedemptionsColumns } from "./fleet-card-redemptions-columns";
import CardRedemptionsFilter from "./fleet-card-redemptions-filter";
import { fleetCardRedemptionsFilterState } from "./fleet-card-redemptions-filter.state";
import styles from "./fleet-card-redemptions.module.scss";
import { prettifyDispenseType, prettifyProduct, prettifyPaymentStatus } from "utils/pretty.utils";
import { capitalize, formatAmount } from "utils/text.utils";
import locale from "localization";
import { formatVolume } from "utils";
import moment from "moment";
import { getCardRedemptions, generateReport } from "apis";
import PaymentStatus from "enums/payment-status";
import { useHistory } from "react-router-dom";
import { SuccessModal } from "components/modals";

const FleetCardRedemptionsModule = (props) => {
  const successModal = useModal();
  const { page } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { location } = useHistory();

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    clearFilter,
    filterCount,
    submitFilter,
    submittedFilter,
  } = useFilter(fleetCardRedemptionsFilterState(fleet.fleetId, location));

  const {
    request: getRedemptionRequest,
    loading: fetchingRedemption,
    result: getRedemptionResult = { data: [], count: 0 },
  } = useApi({
    api: getCardRedemptions,
    pageError: true,
  });

  const { request: generateReportRequest, loading: fetchingReport } = useApi({
    api: generateReport,
    pageError: true,
  });

  useMount(() => {
    setColumns();
    fetchRedemption(requestState);
    page({
      name: Page.ViewRedemptions,
    });
  });

  const setColumns = () => {
    if (fleet.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO) {
      fleetCardRedemptionsColumns.push({ key: "poNumber", text: locale.poNumber, width: "15%" });
    }
  };

  const fetchRedemption = useCallback(
    (rs) => {
      const s = submitFilter(rs);
      getRedemptionRequest({ ...s, productCodes: s.productCodes.join(",") });
    },
    [getRedemptionRequest, submitFilter]
  );

  const preparedRedemptionData = useMemo(() => {
    const { payments } = getRedemptionResult;

    if (payments && payments.length > 0) {
      const preparedData = payments.map((d) => {
        const map = new Map();
        map.set("redemptionId", d.paymentId);
        map.set(
          "redemptionStation",
          <div>
            <Text>{d.stationName}</Text>
            <Text className={styles.subBusinessId}>{d.stationBusinessName}</Text>
          </div>
        );
        map.set(
          "driverIdAndName",
          <div>
            <Text>
              {d?.driver?.firstName
                ? `${capitalize(d.driver?.firstName)} ${capitalize(d.driver?.lastName)}`
                : locale.any}
            </Text>
            <Text className={styles.subBusinessId}>{d.driver?.driverLicenseId || locale.any}</Text>
          </div>
        );
        map.set(
          "dispensedToPlateNumber",
          <div>
            <Text>{d.dispensedTo ? prettifyDispenseType(d.dispensedTo) : locale.any}</Text>
            <Text className={styles.subBusinessId}>{d.vehicle?.plateNumber || locale.any}</Text>
          </div>
        );
        map.set(
          "redemptionDate",
          <div>
            <Text>{moment(d.paidAt).format(DateTime.A)}</Text>
            <Text>{moment(d.paidAt).format(DateTime.B)}</Text>
          </div>
        );
        map.set(
          "fuelCodeRedemptionDate",
          <div>
            <Text>{d.cardNumber}</Text>

            <Text className={styles.subBusinessId}> {moment(d.paidAt).format(DateTime.S)}</Text>
          </div>
        );
        map.set(
          "productVolume",
          <div>
            <Product
              grass={d.productCode === Products.Gas91}
              salsa={d.productCode === Products.Gas95}
              deepBlue={d.productCode === Products.Gas97}
              cheddar={d.productCode === Products.Diesel}
              purple={d.productCode === Products.Lubes}
            >
              {prettifyProduct(d.productCode)}
            </Product>
            <Text className={styles.subBusinessId}> {formatVolume(d.literVolume)}</Text>
          </div>
        );
        map.set("pumpPrice", <div className="nowrap">{`${formatAmount(d.pumpPrice)}/L`}</div>);
        map.set("amount", <div className="nowrap">{`${formatAmount(d.amount)}`}</div>);
        map.set("discount", <div className="nowrap">{`${formatAmount(d.totalDiscount)}`}</div>);
        map.set("discountPerLiter", <div className="nowrap">{`${formatAmount(d.discount)}`}</div>);
        map.set("finalAmount", <div className="nowrap">{`${formatAmount(d.finalAmount)}`}</div>);
        map.set("pointsEarned", <div className="nowrap">{d.pointsEarned || "0"}</div>);
        fleet.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO && map.set("poNumber", d.poNumber);
        map.set("orNumber", d.orNumber);
        map.set(
          "status",
          <Pill
            grass={d.status === PaymentStatus.Success}
            cement={d.status === PaymentStatus.Voided}
          >
            {prettifyPaymentStatus(d.status)}
          </Pill>
        );

        map.set(
          "remarks",
          d.vehicle?.remarks ? (
            <PopOver content={<div className={styles.remarks}>{d?.remarks}</div>}>
              {d.status === PaymentStatus.Voided && <div className="link">View</div>}
            </PopOver>
          ) : (
            ""
          )
        );

        return map;
      });
      return preparedData;
    }
    return [];
  }, [fleet.plbType, getRedemptionResult]);

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate, page: 1 });
    },
    [modifyFilters]
  );

  const onProductChangeCb = useCallback(
    (productCodes) => {
      modifyFilters({ productCodes, page: 1 });
    },
    [modifyFilters]
  );

  const onStationChangeCb = useCallback(
    (redemptionStation) => {
      modifyFilters({ redemptionStation, stationIds: redemptionStation.value, page: 1 });
    },
    [modifyFilters]
  );

  const onDispenseChangeCb = useCallback(
    (value) => {
      modifyFilters({ dispensedTo: value.value, page: 1 });
    },
    [modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1, perPage: 10 });
      fetchRedemption(requestState);
    },
    [modifyFilters, fetchRedemption]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchRedemption(requestState);
    },
    [modifyFilters, fetchRedemption]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchRedemption(requestState);
    },
    [modifyFilters, fetchRedemption]
  );
  const onPaymentStatusChangeCb = useCallback(
    (name, { value }) => {
      modifyFilters({ paymentStatus: value, page: 1 });
    },
    [modifyFilters]
  );

  const onDownloadFileCb = async () => {
    const { requestState } = modifyFilters({ reportType: "fuel-card" });
    if (requestState.redemptionStation) {
      requestState.stationIds = requestState.redemptionStation.value;
      delete requestState.redemptionStation;
    }

    if (requestState.stationIds === null) {
      delete requestState.stationIds;
    }

    delete requestState.perPage;
    delete requestState.page;
    const generateResult = await generateReportRequest(requestState);
    if (generateResult?.downloadUrl) {
      window.open(generateResult?.downloadUrl, "_blank");
    } else {
      successModal.show({
        title: locale.successTitle,
        content: locale.reportGenerated,
      });
    }
  };

  return (
    <div>
      <SuccessModal {...successModal} />
      <div>
        <Intro title={locale.redemptions} subtitle={locale.viewTrackRedemptionsFleetCard} />
      </div>
      <div className={styles.filters}>
        <CardRedemptionsFilter
          onPaymentStatusChangeCb={onPaymentStatusChangeCb}
          onProductChangeCb={onProductChangeCb}
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onStationChange={onStationChangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          onDispenseChange={onDispenseChangeCb}
          onDownloadFile={onDownloadFileCb}
          fetchingReport={fetchingReport}
          resetFilter={() => {
            modifyFilters({ submittedFilter });
          }}
          filterCount={filterCount}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchRedemption(requestState);
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchRedemption(requestState);
          }}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingRedemption}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={fleetCardRedemptionsColumns}
          data={preparedRedemptionData}
          dataCount={getRedemptionResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
          onPaymentStatusChangeCb={onPaymentStatusChangeCb}
        />
      </div>
    </div>
  );
};

export default FleetCardRedemptionsModule;
