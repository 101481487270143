import React, { useMemo } from "react";

import { Intro } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import { mapFilterToRequest, mapDataToList } from "./non-fuel.mapper";
import { columnsNonManual } from "./non-fuel-column";
import { columnsManual } from "./non-fuel-column-manual";
import { initialFilterState } from "./non-fuel-filter.state";
import { useModal, useExport } from "hooks";
import NonFuelFilter from "./non-fuel-filter";
import { getNonFuelTransaction } from "apis/non-fuel.api";
import { ConfirmModal, SuccessModal } from "components/modals";
import { useContext } from "react";
import { FleetContext } from "contexts";
import { generateReport } from "apis/generate-report.api";
import { PLBTypes } from "enums";

const NonFuelModule = () => {
  const confirmModal = useModal();

  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;

  const columns = useMemo(() => {
    return fleet.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO ? columnsManual : columnsNonManual;
  }, [fleet]);

  const { filter, search, table } = useDataTable({
    api: {
      api: getNonFuelTransaction,
      params: {
        fleetId,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "merchantPayments",
      mapper: (merchantPayments) =>
        mapDataToList({
          merchantPayments,
        }),
      columns,
    },
  });

  const exports = useExport({
    api: generateReport,
    hasModal: true,
    reportType: "merchant-payment",
    platformType: "plb",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      fleetId,
      searchKey: search.searchKey,
      redemptionStationIds: filter?.mappedFilterState?.redemptionStationIds,
    },
  });

  return (
    <>
      <ConfirmModal {...confirmModal} />
      <Intro
        title={locale.nonFuelTransactions}
        subtitle={locale.monitorNonFuelTransactionsToFleetPortal}
      />
      <NonFuelFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} paginationV2={true} />
      <SuccessModal {...exports?.successModalComponent} />
    </>
  );
};

export default NonFuelModule;
